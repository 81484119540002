

import animate from './../global/animate';


function SlideInMenu(name = 'menu', menuSelector, buttonOpenSelector, buttonCloseSelector, buttonToggleSelector, openClass) {

	this.isOpen = false;
	this.name = name;

	this.selectors = {
		menu: menuSelector,
		buttonOpen : buttonOpenSelector,
		buttonClose : buttonCloseSelector,
		buttonToggle : buttonToggleSelector
	}

	this.classNames = {
		open : openClass
	}

	this.$menu = $(this.selectors.menu)
	this.$buttonOpen = $(this.selectors.buttonOpen)
	this.$buttonClose = $(this.selectors.buttonClose)
	this.$buttonToggle = $(this.selectors.buttonToggle)

	this.openedElem = null;

	this.callback = () => {};
}


// static constants
SlideInMenu.BEFORE_OPEN = 'before-open';
SlideInMenu.AFTER_OPEN = 'after-open';

SlideInMenu.BEFORE_CLOSE = 'before-close';
SlideInMenu.AFTER_CLOSE = 'after-close';


SlideInMenu.prototype.init = function() {
	this.$buttonOpen.on('click', this.openMenu.bind(this));
	this.$buttonClose.on('click', this.closeMenu.bind(this));
	this.$buttonToggle.on('click', this.toggleMenu.bind(this));

	// escape key close
	$(document).on('keydown', (event) => {
	 	if (event.which === 27 && this.isOpen === true) this.closeMenu.bind(this)();
	});

	// initial aria states
	this.$buttonToggle.attr('aria-expanded', false);
	this.$menu.attr('aria-hidden', true);
	this.$menu.attr('tabindex', '-1');
}


SlideInMenu.prototype.setCallback = function(callback = function() {}) {
	this.callback = callback;
}


SlideInMenu.prototype.openMenu = function(event) {	
	this.openedElem = event.currentTarget;
	this.isOpen = true;
	this.callback(SlideInMenu.BEFORE_OPEN);

	animate.show(this.$menu, this.classNames.open, () => {
		this.callback(SlideInMenu.AFTER_OPEN);
		// focus in callback after animation because it caused weird layout bug
		this.$menu[0].focus();
	});

	this.$buttonToggle.attr('aria-expanded', true);
	this.$menu.attr('aria-hidden', false);
	this.$menu.attr('tabindex', '0');
	// set all buttonToggles to display open state
	this.$buttonToggle.addClass(this.classNames.open);
	return false;
}


SlideInMenu.prototype.closeMenu = function(event) {
	this.isOpen = false;
	this.callback(SlideInMenu.BEFORE_CLOSE);

	animate.hide(this.$menu, this.classNames.open, () => {
		this.callback(SlideInMenu.AFTER_CLOSE);
	});

	// refocus original element
	this.openedElem.focus();
	this.$buttonToggle.attr('aria-expanded', false);
	this.$menu.attr('aria-hidden', true);

	this.$buttonToggle.removeClass(this.classNames.open);
	this.openedElem = null;
	return false;
}


SlideInMenu.prototype.toggleMenu = function(event) {
	if (this.isOpen) {
		this.closeMenu(event);
	} else {
		this.openMenu(event);
	}
}


export default SlideInMenu;



